<template>
  <div class="serviceMain">
    <div class="searchBar">
      <div class="tittle"> 我的取证 </div>
      <div class="rightForm">
        <el-input
          v-model="form.subject"
          placeholder="请输入作品名称"
        ></el-input>
        <el-date-picker
          v-model="form.date"
           value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
        <el-button @click="fetchData">查询</el-button>
        <router-link :to="{path:'/ObtainEvidence/InputForm'}">
            <el-button type="primary">新增取证</el-button>
        </router-link>
      </div>
    </div>

    <el-table
      :data="tableData"
      :cell-class-name="tabelCellClass"
      style="width: 100%"
      @row-click="onTabelRowClick"
    >
      <el-table-column
        prop="subject"
        label="取证名称"
        width="160"
         show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="orderNo"
        label="订单号"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="num"
        label="数量"
        sortable
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="createTimeText"
        label="创建时间"
        sortable
        width="150"
      >
        <!-- eslint-disable-next-line -->
       <template slot="header" slot-scope="scope">
          <div class="createTimeHeader">
            <span>创建时间</span>
            <a-tooltip>
              <template slot="title">
                prompt text
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="typeDesc"
        label="类型"
        width="100"
      >
      </el-table-column> -->
      <el-table-column
        prop="certificate"
        label="证书"
        width="180"
      >
        <template slot-scope="scope">
          <div class="signList">
               <span
              class="graySpan"
            >
              取
           
            </span>
          </div>
        </template>
      </el-table-column>
       <el-table-column
        prop="status"
        label="状态"
        width="100"
      >
        <template slot-scope="scope">
          <span
            class="stausCell"
            :class="{'stausCell1':scope.row.status=='10','stausCell2':scope.row.status=='11','stausCell3':scope.row.status=='21'}"
          >
            {{scope.row.statusText}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="100"
        fixed="right"
      >
        <!-- eslint-disable-next-line -->
        <template slot-scope="scope">
          <!-- {{scope.row.status}} -->
       
               <a-dropdown
            :trigger="['click']"
            placement="bottomLeft"
            overlayClassName="moveLeft"
          >
            <a @click.stop="e => e.preventDefault()">
              <a-icon type="more" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                获取二维码
              </a-menu-item>
            </a-menu>
          </a-dropdown>

          <a-icon type="right" class="ml12 gray" />
       
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
       :total="pagination.total"
      :current-page.sync="pagination.page"
      :page-size.sync="pagination.pageSize"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>

  </div>
</template>

<script>
import {queryObtainOrders} from '@/api/ObtainEvidence'
export default {
  name: 'ObtainEvidence',

  data () {
    return {
      form: {
         subject: null,
        date: null
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total:0
      },
      tableData: [],

    }
  },
  mounted(){
    this.fetchData();
  },
  methods: {
    onTabelRowClick(row){
      this.$router.push({
        path: "/ObtainEvidence/Detail",
        query: {orderNo:row.orderNo}
      });
    },
    tabelCellClass ({ row, column, rowIndex, columnIndex }) {
      if (column.property == 'certificate') {
        return 'certificateCell'
      }
    },
        getStatus(code){
        switch(code){
          case '10':return '待付款';
          case '11':return '待处理';
          case '21':return '已完成';
          case '31':return '失败';
        }
    },
    fetchData (param) {
      queryObtainOrders(this.pagination.page,this.pagination.pageSize,this.form).then(({data})=>{
         for (let i = 0; i < data.list.length; i++) {
            const element = data.list[i];
             element.num=element.items.length;
             element.statusText=this.getStatus(element.status);
              element.createTimeText= this.dayjs(element.createTime*1000).format('YYYY-MM-DD HH:mm:ss ');
      // this.orderInfo.updateTimeText= this.dayjs(this.orderInfo.updateTime*1000).format('YYYY-MM-DD HH:mm:ss ');
          }
          this.tableData=data.list;
          this.pagination.total=data.total*1;
        });
    },
    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      this.fetchData()
    },
    currentChange (pageNum) {
      // 更新表格数据
      this.fetchData()
    },
  }
}
</script>

<style lang='scss' scoped>
@import '../../../assets/css/mine.scss';
</style>
